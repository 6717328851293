import React, { Component } from 'react';

class Ticker2 extends Component {


    render() {

        return (

          <div class="ticker-wrap-2">
            <div class="ticker-wrap">
            <div class="ticker">
              <div class="ticker__item">100 Billion of BAM</div>
              <div class="ticker__item">100 Billion of BAM</div>
              <div class="ticker__item">100 Billion of BAM</div>
              <div class="ticker__item">100 Billion of BAM</div>
              <div class="ticker__item">100 Billion of BAM</div>
              <div class="ticker__item">100 Billion of BAM</div>
              <div class="ticker__item">100 Billion of BAM</div>
              <div class="ticker__item">100 Billion of BAM</div>
              <div class="ticker__item">100 Billion of BAM</div>
              <div class="ticker__item">100 Billion of BAM</div>
              <div class="ticker__item">100 Billion of BAM</div>
              <div class="ticker__item">100 Billion of BAM</div>
              <div class="ticker__item">100 Billion of BAM</div>
              <div class="ticker__item">100 Billion of BAM</div>
              <div class="ticker__item">100 Billion of BAM</div>
              <div class="ticker__item">100 Billion of BAM</div>
              <div class="ticker__item">100 Billion of BAM</div>
              <div class="ticker__item">100 Billion of BAM</div>
            </div>
            </div>
            </div>)
    }
}

export default Ticker2;