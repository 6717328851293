import React, { Component } from 'react';

class Ticker5 extends Component {


    render() {

        return (

          <div class="ticker-wrap-5">
            <div class="ticker-wrap">
            <div class="ticker">
              <div class="ticker__item">Mint Your Destiny!</div>
              <div class="ticker__item">Mint Your Destiny!</div>
              <div class="ticker__item">Mint Your Destiny!</div>
              <div class="ticker__item">Mint Your Destiny!</div>
              <div class="ticker__item">Mint Your Destiny!</div>
              <div class="ticker__item">Mint Your Destiny!</div>
              <div class="ticker__item">Mint Your Destiny!</div>
              <div class="ticker__item">Mint Your Destiny!</div>
              <div class="ticker__item">Mint Your Destiny!</div>
              <div class="ticker__item">Mint Your Destiny!</div>
              <div class="ticker__item">Mint Your Destiny!</div>
              <div class="ticker__item">Mint Your Destiny!</div>
              <div class="ticker__item">Mint Your Destiny!</div>
              <div class="ticker__item">Mint Your Destiny!</div>
              <div class="ticker__item">Mint Your Destiny!</div>
              <div class="ticker__item">Mint Your Destiny!</div>
              <div class="ticker__item">Mint Your Destiny!</div>
              <div class="ticker__item">Mint Your Destiny!</div>
            </div>
            </div>
            </div>)
    }
}

export default Ticker5;