import React, { Component, useEffect } from 'react';
import '../App.css';
import "aos/dist/aos.css";
import Aos from 'aos';
import presale from '../assets/profit-up.png';
import lp from '../assets/nature.png';
import rewards from '../assets/airdrop.png';
import development from '../assets/asset-management.png';
import marketing from '../assets/handshake.png';
import reserve from '../assets/venture.png';

const Stry = () => {
    useEffect(() => {
        Aos.init({ duration: 4000 });
    }, [])
}

class Story extends Component {

    render() {
        return (

            <div class="boxWrap2Story2">

                <div class="aboutDistribution">
                    <div class="storyConToken">
                        <div class="conT2"><span className='hl2-1'>DISTRIBUTION OF</span> <span class="hl2">$BAM TOKENS</span></div>
                        <p>
                            <div className='detailsAndCon'>
                                <div>
                                    <span className='headerHL'>Presale:</span> 35% of the total supply is earmarked for presale events to jumpstart participation and fund initial project development.
                                </div>

                                <img src={presale} />
                            </div>
                        </p>

                        <p>
                            <div className='detailsAndCon'>
                                <div>
                                <span className='headerHL'>Liquidity Pool:</span> 30%
                                </div>

                                <img src={lp} />
                            </div>
                        </p>

                        <p>
                            <div className='detailsAndCon'>
                                <div>
                                <span className='headerHL'>Community Rewards and Airdrops:</span> 10% is set aside to engage and reward the community through airdrops, contests, and other engagement-driven activities.
                                </div>

                                <img src={rewards} />
                            </div>
                        </p>

                        <p>
                            <div className='detailsAndCon'>
                                <div>
                                <span className='headerHL'>Development Fund:</span> 10% is reserved for ongoing development, ensuring continuous improvement and innovation within the Basemew ecosystem.
                                </div>
                                <img src={development} />
                            </div>
                        </p>

                        <p>
                            <div className='detailsAndCon'>
                                <div>
                                <span className='headerHL'>Marketing and Partnerships:</span> 10% is allocated to marketing efforts and forming strategic partnerships to increase Basemew’s reach and adoption.
                                </div>
                                <img src={marketing} />
                            </div>
                        </p>

                        <p>
                            <div className='detailsAndCon'>
                                <div>
                                <span className='headerHL'>Reserve Fund:</span> 5% is kept in reserve to address any unforeseen challenges or opportunities that may arise.
                                </div>
                                <img src={reserve} />
                            </div>
                        </p>
                    </div>

                </div>

            </div>
        )
    }
}

export default Story;

